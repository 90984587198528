import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Config from "../../../config";

const LegalDocumentReview = () => {
  let { test_id } = useParams();
  const history = useNavigate();
  const location = useLocation();
  const loaderMainWrap = useRef();

  const [question, setQuestion] = useState([]);
  const [formData, setFormData] = useState({});

  const [isFormEditable, setIsFormEditable] = useState(false);
  const [pageTitle, setPageTitle] = useState('Final Review');

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    const getFormData = {
      userId: userId,
      test_id: test_id,
    };
    const params = new URLSearchParams(getFormData).toString();
    Config.get(`/question/getData?${params}`).then((res) => {
      const result = {};
      for (const key in res.data) {
        const innerObj = {};
        innerObj[key] = res.data[key][key];
        result[key] = innerObj;
      }
      const resultJSON = JSON.stringify(result);
      if (resultJSON !== "{}") {
        const savedFormData = resultJSON;
        if (savedFormData) {
          setFormData(JSON.parse(savedFormData));
        }
      }
    });

    if(location.state){
      setIsFormEditable(true);
      setPageTitle(location.state.action)
    }
  }, []);

  const getLegalDocumentQuestionnaire = () => {
    const userId = localStorage.getItem("user_id");
    Config.get(`/user/get-questionnaire?_id=${test_id}&userId=${userId}`)
      .then((res) => {

        if (res.status) {
          if (res.data.length > 0) {
            var questionArray = [];
            var questionAgreementArray = [];
            res.data.forEach((items) => {
              questionArray.push({
                ...items,
                answer: "",
                multipleAnswer: [],
                error: "",
              });

              if (items.Options.length > 0) {
                items.Options.forEach((itemsOptions) => {
                  questionAgreementArray.push({
                    id: itemsOptions._id,
                    textDocumentOption: itemsOptions.textDocumentOption
                      ? itemsOptions.textDocumentOption
                      : "",
                  });
                });
              } else if (items.questionType === "free") {
                questionAgreementArray.push({
                  id: items._id,
                  textDocumentOption: items.text_document
                    ? items.text_document
                    : "",
                });
              }
            });

            for (let i of questionArray) {
              for (let j of res.testResult.LegalDocTestResultQus) {
                if (i._id === j.testQuestionId) {
                  i.answer = j.answer;
                  i.multipleAnswer = [...j.optionId];
                }
              }
            }

            if (questionArray.length === 0) {
              Config.showToast("No questions found.", "danger");
            } else {
              setQuestion(questionArray);
            }
          } else {
            Config.showToast("No questions found.", "danger");
            loaderMainWrap.current.style.display = "none";
          }
        }
      })
      .catch((err) => {
        console.log(err, "getLegalDocumentQuestionnaire");
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getLegalDocumentQuestionnaire();
    document.title = "Legal document questionnaire";
  }, []);

  const setAnswer = (questionType, index, questionIndex, val) => {
    const updatedFormData = { ...formData };
    if (!updatedFormData[index]) {
      updatedFormData[index] = {};
    }
    updatedFormData[index][questionIndex] = {
      value: val,
      questionType: questionType,
    };

    setFormData(updatedFormData);
    if (questionType === "free" || questionType === "singlechoice") {
      var questionIndexArray = question[index];
      questionIndexArray.answer = val;
      questionIndexArray.error = "";

      question[index] = questionIndexArray;

      var newArray = [];
      question.forEach((items) => {
        newArray.push(items);
      });
      setQuestion(newArray);
    } else if (questionType === "multiplechoice") {
      var questionIndexArray = question[index];
      var index = questionIndexArray.multipleAnswer.indexOf(val);
      if (index !== -1) {
        questionIndexArray.multipleAnswer.splice(index, 1);
      } else {
        questionIndexArray.multipleAnswer.push(val);
      }
      questionIndexArray.error = "";

      question[index] = questionIndexArray;
      var newArray = [];
      question.forEach((items) => {
        newArray.push(items);
      });

      updatedFormData[questionIndex][questionIndex] = {
        value: newArray[questionIndex].multipleAnswer.filter(
          (element) => element !== ""
        ),
        questionType: questionType,
      };

      setFormData(updatedFormData);
    }
  };

  const handleQuestionSubmit = () => {
    saveQuestions();
  };

  const saveQuestions = () => {
    var answerArray = [];

    question.forEach((items) => {
      if (
        (items.questionType === "free" ||
          items.questionType === "singlechoice") &&
        items.answer
      ) {
        answerArray.push({
          testQuestionId: items._id,
          type: items.questionType,
          answer: items.answer,
          optionId: [],
        });
      } else if (
        items.questionType === "multiplechoice" &&
        items.multipleAnswer &&
        items.multipleAnswer.length > 0
      ) {
        answerArray.push({
          testQuestionId: items._id,
          type: items.questionType,
          answer: items.answer,
          optionId: items.multipleAnswer,
        });
      }
    });

    let data = Object.assign({});

    data["legalDocumentId"] = test_id;
    data["userId"] = localStorage.getItem("user_id");
    data["LegalDocTestResultQus"] = answerArray;

    Config.post("/admin/save-question-year", data)
      .then((res) => {
        if (res.status) {
          Config.showToast(
            "You Have been successfully completed your contract, Now you can download your contract by clicking on Download Button",
            "success"
          );
          history("/agreement");
        }
      })
      .catch((err) => {
        console.log(err, "saveQuestions");
      });
  };

  const handleFreeTextWithQuestionId = (freeTextValue, questionId) => {
    const newAnswerToQuestion = [...question];

    const check = newAnswerToQuestion.find((item) => item._id === questionId);

    if (check) {
      check.answer = freeTextValue;

      if (check.multipleAnswer.length > 1) {
        check.multipleAnswer[check.multipleAnswer.length - 1] = freeTextValue;
      }
    }

    setQuestion(newAnswerToQuestion);
  };

  return (
    <>
      <div className="container">
        <div className={"row"}>
          <p className={"document-title text-center mt-30"}>
            <b>{pageTitle}</b>
          </p>
        </div>

        {question.length === 0 && (
          <div
            className={"row no-record"}
            style={{ textAlign: "center", display: "block" }}
          >
          </div>
        )}
        {question &&
          question.map((item, index) => {
            return (
              <>
                {
                  <div key={index} className={"row question"}>
                    <div className={"question-card col-md-12"}>
                      <p className={"question-title"}>
                        <b>{item.question}</b>
                      </p>
                      {item.texHelp && (
                        <p className={"question-text-help"}>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="question-info-icon"
                          >
                            <path
                              d="M9 4.09091C10.302 4.09091 11.5506 4.60812 12.4713 5.52875C13.3919 6.44938 13.9091 7.69803 13.9091 9C13.9091 10.8164 12.9191 12.4036 11.4545 13.2545V14.7273C11.4545 14.9443 11.3683 15.1524 11.2149 15.3058C11.0615 15.4593 10.8534 15.5455 10.6364 15.5455H7.36364C7.14664 15.5455 6.93853 15.4593 6.78509 15.3058C6.63166 15.1524 6.54545 14.9443 6.54545 14.7273V13.2545C5.08091 12.4036 4.09091 10.8164 4.09091 9C4.09091 7.69803 4.60812 6.44938 5.52875 5.52875C6.44938 4.60812 7.69803 4.09091 9 4.09091ZM10.6364 16.3636V17.1818C10.6364 17.3988 10.5502 17.6069 10.3967 17.7604C10.2433 17.9138 10.0352 18 9.81818 18H8.18182C7.96482 18 7.75672 17.9138 7.60328 17.7604C7.44984 17.6069 7.36364 17.3988 7.36364 17.1818V16.3636H10.6364ZM15.5455 8.18182H18V9.81818H15.5455V8.18182ZM0 8.18182H2.45455V9.81818H0V8.18182ZM9.81818 0V2.45455H8.18182V0H9.81818ZM3.20727 2.04545L4.95 3.79636L3.78818 4.95L2.04545 3.21545L3.20727 2.04545ZM13.05 3.78818L14.7845 2.04545L15.9545 3.21545L14.2118 4.95L13.05 3.78818Z"
                              fill="#1FAAFF"
                            />
                          </svg>
                          {item.texHelp}
                        </p>
                      )}

                      {item.questionType === "free" && (
                        <div className={"form-group"}>
                          <input
                            disabled={!isFormEditable}
                            className="form-control"
                            type={"text"}
                            value={item.answer}
                            onChange={(e) => {
                              setAnswer(
                                item.questionType,
                                index,
                                index,
                                e.target.value
                              );
                            }}
                            required={true}
                          />
                          {item.error && (
                            <span className={"text-danger"}>{item.error}</span>
                          )}
                        </div>
                      )}

                      {item.questionType === "singlechoice" && (
                        <div className={"form-group single-choice"}>
                          {item.Options &&
                            item.Options.map((optionData, optionIndex) => {
                              return (
                                <p key={optionIndex}>
                                  <input
                                    disabled={!isFormEditable}
                                    name={item.questionId}
                                    type={"radio"}
                                    id={optionData._id}
                                    value={optionData._id}
                                    checked={
                                      optionData._id === item.answer
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      setAnswer(
                                        item.questionType,
                                        index,
                                        index,
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <label
                                    htmlFor={optionData._id}
                                    style={{ marginLeft: "8px" }}
                                  >
                                    {optionData.optionValue}
                                  </label>
                                </p>
                              );
                            })}

                          {item.error && (
                            <span className={"text-danger"}>{item.error}</span>
                          )}
                        </div>
                      )}

                      {item.questionType === "multiplechoice" && (
                        <div className={"form-group multiple-choice"}>
                          {item.Options &&
                            item.Options.map((optionData, optionIndex) => {
                              return (
                                <p key={optionIndex}>
                                  <input
                                    disabled={!isFormEditable}
                                    type={"checkbox"}
                                    id={optionData._id}
                                    value={optionData._id}
                                    checked={
                                      item.multipleAnswer.includes(
                                        optionData._id
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      let newQuesArray = [...question];

                                      let oldData = question.filter(
                                        (i) => i._id === item._id
                                      )[0].multipleAnswer;
                                      let checkIfExist = oldData.indexOf(
                                        e.target.value
                                      );

                                      checkIfExist === -1
                                        ? oldData.push(e.target.value)
                                        : oldData.splice(checkIfExist, 1);

                                      newQuesArray.filter(
                                        (i) => i._id === item._id
                                      )[0].multipleAnswer = [...oldData];
                                      newQuesArray.filter(
                                        (i) => i._id === item._id
                                      )[0].multipleAnswer = [
                                        ...new Set(
                                          newQuesArray.filter(
                                            (i) => i._id === item._id
                                          )[0].multipleAnswer
                                        ),
                                      ];

                                      setQuestion(newQuesArray);
                                    }}
                                  />
                                  <label
                                    htmlFor={optionData._id}
                                    style={{ marginLeft: "8px" }}
                                  >
                                    {optionData.optionValue}
                                  </label>
                                </p>
                              );
                            })}
                          {item.is_add_other && (
                            <div className="question-optionbox my-18">
                              <input
                                disabled={!isFormEditable}
                                placeholder="Enter Free Text"
                                value={item.answer}
                                onChange={(e) => {
                                  handleFreeTextWithQuestionId(
                                    e.target.value,
                                    item._id
                                  );
                                }}
                              />
                            </div>
                          )}
                          {item.error && (
                            <span className={"text-danger"}>{item.error}</span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                }
              </>
            );
          })}
        {question.length !== 0 && (
          <>
            <div className="divider"></div>
            <div className="d-flex justify-content-center">
              <button
                className="btn submit-btn1 mx-10"
                onClick={() => setIsFormEditable(!isFormEditable)}
              >
                {!isFormEditable ? "Edit" : "Save"}
              </button>
              <button
                disabled={isFormEditable}
                className="btn submit-btn1 mx-10"
                onClick={handleQuestionSubmit}
              >
                Submit
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LegalDocumentReview;
